import LogRocket from "logrocket";

export function createLog(email) {

  if (LogRocket) {
    LogRocket.init("6dlu2v/datagma")
    LogRocket &&
      LogRocket.identify(email, {
        name: email,
        email: email,
      });
  }
}

